import { render, staticRenderFns } from "./SampleStrategyPage2.vue?vue&type=template&id=17b053b8&scoped=true&"
import script from "./SampleStrategyPage2.vue?vue&type=script&lang=js&"
export * from "./SampleStrategyPage2.vue?vue&type=script&lang=js&"
import style0 from "./SampleStrategyPage2.vue?vue&type=style&index=0&id=17b053b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b053b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VExpansionPanel,VExpansionPanelContent,VExpansionPanels,VImg,VMain})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
