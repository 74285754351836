<template>
  <v-app>
    <strategy-info-side-menu></strategy-info-side-menu>
    <v-main
      id="scrolling-main-area"
      class="overflow-y-auto"
      style="height: 0;"
      v-scroll.self="onScroll"
    >
      <v-img
        class="ma-3 mb-0"
        alt="Branding Technology"
        max-height="150"
        max-width="400"
        src="../../assets/images/btlogo.png"
      ></v-img>
      <div class="pa-8 pt-5">
        <v-expansion-panels :value="[0, 1, 2, 3, 4, 5]" multiple tile>
          <v-expansion-panel>
            <v-expansion-panel-header-with-label label="経営理念" status="notInput"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <manegement-philosophy></manegement-philosophy>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header-with-label label="医院に関する情報" status="edit"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <company-profile></company-profile>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header-with-label label="ビジョン" status="done" :switch="true"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <company-vision></company-vision>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header-with-label label="現状の戦略と実施施策" status="notInput"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <current-strategy-and-action></current-strategy-and-action>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header-with-label label="診療内容" status="edit"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <service></service>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="bottommost-section">
            <v-expansion-panel-header-with-label label="競合分析" status="done" :switch="true"></v-expansion-panel-header-with-label>
            <v-expansion-panel-content>
              <competitors></competitors>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
      </div>
    </v-main>
    <bottom-panel></bottom-panel>
  </v-app>
</template>
<script>
import StrategyInfoSideMenu from '../../components/atomic/organisms/StrategyInfoSideMenu';
import VExpansionPanelHeaderWithLabel from '../../components/atomic/atoms/VExpansionPanelHeaderWithLabel.vue';
import ManegementPhilosophy from '@/components/atomic/organisms/bp/ManegementPhilosophy.vue'
import CompanyProfile from '@/components/atomic/organisms/bp/CompanyProfile.vue'
import CompanyVision from '@/components/atomic/organisms/bp/CompanyVision.vue'
import CurrentStrategyAndAction from '@/components/atomic/organisms/bp/CurrentStrategyAndAction';
import Service from '@/components/atomic/organisms/bp/Service';
import Competitors from '@/components/atomic/organisms/bp/Competitors';
import BottomPanel from '../../components/atomic/organisms/BottomPanel';

export default {
  components: {
    StrategyInfoSideMenu,
    VExpansionPanelHeaderWithLabel,
    ManegementPhilosophy,
    CompanyProfile,
    CompanyVision,
    CurrentStrategyAndAction,
    Service,
    Competitors,
    BottomPanel
  },
  data() {
    return {
      progress: 0,
      hideDetail: false,
      bgcolor: "blue darken-1",
      height: "65",
      sheet: false
    };
  },
  computed: {
    progressColor () {
      if (this.progress == 0) return 'grey'
      if (this.progress == 1) return 'indigo'
      if (this.progress == 2) return 'green'
      if (this.progress == 3) return 'orange'
      return 'red'
    },
  },
  methods: {
    onScroll(e) {
      this.hideDetail = e.target.scrollTop > 50
    },

  },
}
</script>
<style lang="scss" scoped>
  .bottommost-section {
    margin-bottom: 420px;
  }
</style>