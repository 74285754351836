<template>
  <div v-if="project">
    <div class="sheet-button text-center mb-2">
      <v-btn
        v-if="!sheet"
        class="mx-2"
        elevation="4"
        fab
        small
        dark
        :color="bgcolor"
        @click="sheet = !sheet"
      >
        <v-icon dark>
          mdi-dots-horizontal
        </v-icon>
      </v-btn>
    </div>
    <v-bottom-sheet
      v-model="sheet"
      class="pa-0 elevation-0 align-center"
      color="white"
      :background-color="bgcolor"
      :height="height"
      hide-overlay
      dark
    >
      <v-sheet
        class="elevation-0 text-center"
        :color="bgcolor"
        :height="height"
      >
        <v-row class="ma-0 pa-0" justify="center">
          <v-col class="ma-0 pa-0" md="2">
            <v-btn :color="bgcolor" :height="height" class="elevation-0" @click="fireRoutingEvent({ name: 'TopPage' })">
              <v-icon left>mdi-home</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">Topに戻る</span>
              <span v-else class="text-caption">Topに戻る</span>
            </v-btn>
          </v-col>

          <v-col class="ma-0 pa-0" md="2">
            <v-btn :color="bgcolor" :height="height" class="elevation-0" @click="fireRoutingEvent({ name: 'SearchPage' })">
              <v-icon left>mdi-magnify</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">プロジェクト検索</span>
              <span v-else class="text-caption">プロジェクト検索</span>
            </v-btn>
          </v-col>

          <v-col class="ma-0 pa-0" md="2">
            <v-btn :color="bgcolor" :height="height" class="elevation-0" @click="fireRoutingEvent({ name: 'ProjectEdit', query: { p: project.id }})" :disabled="!hasWritePermission">
              <v-icon left>mdi-pencil</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">プロジェクト編集</span>
              <span v-else class="text-caption">プロジェクト編集</span>
            </v-btn>
          </v-col>

          <v-col class="ma-0 pa-0" md="2">
            <v-btn :color="bgcolor" :height="height" class="elevation-0" @click="openSlideExportDialog()">
              <v-icon left>mdi-file-export-outline</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">スライドを出力</span>
              <span v-else class="text-caption">スライドを出力</span>
            </v-btn>
          </v-col>

          <v-col class="ma-0 pa-0" md="2">
            <v-btn :color="bgcolor" :height="height" class="elevation-0" @click="logout()">
              <v-icon left>mdi-logout</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">ログアウト</span>
              <span v-else class="text-caption">ログアウト</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <!-- スライド選択ダイアログ -->
    <slide-export-dialog
      v-model="slideExportDialog"
      @selected="exportGoogleSlides($event)"
      @canceled="closeSlideExportDialog()"
    ></slide-export-dialog>

    <!-- スライド出力ダイアログ -->
    <slide-export-completed-dialog
      v-model="slideExportCompletedDialog"
      :slideUrl="slideUrl"
      @closed="slideExportCompletedDialog = false"
    >
    </slide-export-completed-dialog>

    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {RepositoryFactory} from '../../../api/RepositoryFactory'

import SlideExportDialog from "../molecules/SlideExportDialog.vue";
import SlideExportCompletedDialog from "../molecules/SlideExportCompletedDialog.vue";

export default {
  components: {
    SlideExportDialog,
    SlideExportCompletedDialog,
  },
  data(){
    return{
      bgcolor: "blue darken-4",
      height: "65",
      sheet: false,

      //スライド選択ダイアログ表示用
      slideExportDialog: false,

      //スライド出力結果ダイアログ表示用
      slideExportCompletedDialog: false,
      slideUrl: null,
      resultCode: null,

      //スライド出力loadingモーション用
      absolute: true,
      overlay: false,
    }
  },
  computed: {
    ...mapGetters(['project', 'hasWritePermission']),
  },

  methods: {
    fireRoutingEvent(param) {
      this.$emit('routing', () => this.$router.push(param))
    },

    logout() {
      this.$emit('routing', () => {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpirationDate')
        this.$store.commit('accessToken', null)
        this.$router.push({ name: 'LoginPage' });
      })
    },

    async exportGoogleSlides(val) {

      //loadingモーションON
      this.overlay = true;

      //TODO: 仮でスライド出力APIをここで呼び出し
      const slidesRepository = RepositoryFactory.get('slides');
      const projectId = this.project.id;
      const params = {
        //pageIds: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        pageIds: val
      };

      const response = await slidesRepository.export( projectId, params );
      if (response.data.data.slide) {
        console.log('slidesExport response:', response.data.data.slide);
        this.slideUrl = response.data.data.slide.url;
      } else {
        this.slideUrl = '';
      }

      this.slideExportDialog = false;
      this.slideExportCompletedDialog = true;

      //loadingモーションOFF
      this.overlay = false;
    },

    openSlideExportDialog() {
      this.slideExportDialog = true;
    },

    closeSlideExportDialog() {
      this.slideExportDialog = false;
    },
  },
}
</script>
<style lang="scss" scoped>
  .sheet-button {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 999;
  }
</style>