<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="360px"
    >
      <v-card>
        <v-card-title>スライド出力</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-checkbox
            v-for="(slide, i) in slides" :key="i"
            v-model="selected"
            :label="slide"
            :value="i+1"
            color="btPrimary"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="pa-3 mr-3 text-body-1"
            width="126"
            color="primary"
            large
            depressed
            @click="selectedOK()"
          >
            出力
          </v-btn>
          <v-btn
            class="pa-3 text-body-1"
            width="126"
            color="grey lighten-2"
            large
            depressed
            @click="exportCancel()"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  props: ["value"],
  data() {
    return {
      slides: ['課題・目標', '戦略整理', '強み・差別化ポイント', 'ターゲット', 'ペルソナ', 'カスタマージャーニーマップ', '競合情報', 'STP', '5Force分析', 'PEST分析', '4P・4C分析', '4P競合比較分析', 'ビジネスモデルキャンバス', '戦略まとめ'],
      selected: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      dialog: false,
    }
  },
  methods: {
    selectedOK() {
      this.dialog = false;
      this.$emit("selected", this.selected);
      //初期化
      this.selected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    },
    exportCancel() {
      this.dialog = false;
      this.$emit("canceled");
      //初期化
      this.selected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.dialog = val;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
